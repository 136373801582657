import React from 'react'

const UserInfo = () => {
  return (
    <div className='p-2 sm:p-5 flex items-center justify-between'>
         <p class="font-semibold text-xl text-gray-800 ">
      Inbox
      </p></div>

  )
}

export default UserInfo