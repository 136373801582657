import { doc, getDoc } from "firebase/firestore";
import { create } from "zustand";
import { db } from "../../../../firebaseConfig";
import { persist, createJSONStorage } from "zustand/middleware";

export const useUserStore = create(
  persist(
    (set) => ({
      currentUser: null,
      isLoading: true,
      fetchUserInfo: async (uid) => {
        if (!uid) return set({ currentUser: null, isLoading: false });

        try {
          const docRef = doc(db, "employers", uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            set({ currentUser: docSnap.data(), isLoading: false });
          } else {
            set({ currentUser: null, isLoading: false });
          }
        } catch (err) {
          console.log(err);
          return set({ currentUser: null, isLoading: false });
        }
      },
    }),
    {
      name: "current-user", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
